import { PageLayout } from '~/components/layout/page'
import Prefooter from '~/components/sections/homepage/prefooter'
import { useTabletBreakpoint } from '~/hooks/use-media'

const HomePage = () => {
  const isTablet = useTabletBreakpoint()

  const cardData = [
    {
      key: '1',
      title: 'A GAME OF CHANCE',
      description: 'Utilizing Chainlink VRF. How far can you ride?'
    },
    {
      key: '3',
      title: 'COMMUNITY DRIVEN',
      description: 'Bus NFT owners earn a portion of rewards when players win.'
    },
    {
      key: '2',
      title: 'UNLIMITED LEVELS',
      description: 'Game never ends! Levels are unlocked as gameplay ensues.'
    }
  ]

  const cardStyle = {
    border: '2px solid #ff02b3',
    maxWidth: '320px',
    minWidth: '320px',
    color: 'white',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: 'black',
    boxShadow: '0 0 10px #ff02b3',
    transition: 'transform 0.2s',
    background:
      'linear-gradient(to bottom, #333, #1a1a1a, #0a0a0a, #1a1a1a, #333)'
  }

  const headerStyle = {
    padding: '10px',
    backgroundColor: 'rgba(255, 2, 179, 0.2)',
    fontFamily: 'Quickstarter',
    color: '#FFF'
  }

  const bodyStyle = {
    padding: '10px'
  }

  return (
    <PageLayout>
      <Prefooter />
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '20px',
          padding: '20px',
          backgroundColor: 'black',
          margin: 'auto',
          justifyContent: 'center',
          paddingTop: isTablet ? '0px' : '100px'
        }}
      >
        {cardData.map((card) => (
          <div key={card.key} style={cardStyle}>
            <div style={headerStyle}>{card.title}</div>
            <div style={bodyStyle}>{card.description}</div>
          </div>
        ))}
      </div>
    </PageLayout>
  )
}

export default HomePage
