import { gsap, SplitText } from 'lib/gsap'
import dynamic, { LoaderComponent } from 'next/dynamic'
import Link from 'next/link'
import { useEffect, useRef } from 'react'
import { Section } from '~/components/layout/section'
import { Title } from '~/components/primitives/texts'
import { useDeviceDetect } from '~/hooks/use-device-detect'
import { useSearchParams } from 'next/navigation'
import s from './prefooter.module.scss'

const Grid3D = dynamic(
  () =>
    import('~/components/common/grid-3d').then(
      (m) => m.Grid3D
    ) as LoaderComponent,
  {
    ssr: false
  }
)

const Prefooter = () => {
  const { isDesktop } = useDeviceDetect()
  const ctaRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLParagraphElement>(null)
  const pinRef = useRef<HTMLDivElement>(null)
  const sectionRef = useRef<HTMLDivElement>(null)

  const queryParams = useSearchParams()

  // We only forward allowed query params to the dapp
  const referral = queryParams.get('ref')

  useEffect(() => {
    if (!titleRef.current || !ctaRef.current || !isDesktop) {
      return
    }

    // gsap split text
    const split = new SplitText(titleRef.current, {
      type: 'chars',
      charsClass: 'char'
    })

    const timelineConfig: gsap.TimelineVars = {
      defaults: {
        ease: 'power2.inOut'
      },
      paused: true
    }

    if (isDesktop) {
      timelineConfig.scrollTrigger = {
        scrub: 1.2,
        trigger: sectionRef.current,
        start: 'top 30%',
        end: '95% bottom'
      }
    }

    // gsap timeline
    const tl = gsap.timeline(timelineConfig)

    tl.fromTo(
      [split.chars, ctaRef.current],
      {
        opacity: 1,
        z: -50
      },
      {
        z: 0,
        opacity: 1,
        duration: 50,
        stagger: {
          amount: 10,
          from: 'center'
        }
      }
    )

    return () => {
      tl.revert()
      tl.kill()
      tl.scrollTrigger?.kill?.()
      split.revert()
    }
  }, [isDesktop])

  return (
    <Section className={s['section']} id="prefooter" ref={sectionRef}>
      {/* <Image
        loading="eager"
        priority
        src={busPng}
        className={s['grid']}
        quality={100}
        alt="grid"
        style={{
          opacity: 0.5
        }}
        draggable={false}
      /> */}
      <div className={s['pin']} ref={pinRef}>
        {/* <div className={s['stars']}>
          <Sky />
        </div> */}
        <div className={s['top']}>
          <Grid3D />
        </div>
        <div className={s['bottom']}>
          <Grid3D />
        </div>

        <div className={s['content']}>
          {/* split chars but not spaces */}
          <Title className={s['title']} ref={titleRef}>
            Ride the bus
          </Title>

          {/* <div className={s['comingSoon']} ref={ctaRef}>
            Coming Soon
          </div> */}
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              justifyContent: 'center'
            }}
          >
            <Link
              className={s['comingSoon']}
              href={`https://app.ridethebus.xyz${
                referral ? `?ref=${referral}` : ''
              }`}
              aria-label="enter-app"
            >
              Enter app
            </Link>
            <Link className={s['comingSoon']} href="/about" aria-label="about">
              About
            </Link>
            <Link
              className={s['comingSoon']}
              href="/roadmap"
              aria-label="roadmap"
            >
              Roadmap
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Prefooter
